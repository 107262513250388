import React from "react";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitles,
  FooterLink,
  SocialMedia,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialMediaWrap,
  WebsiteRights,
} from "./FooterElements";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitles>About Us </FooterLinkTitles>
              <FooterLink to="/inquiry">Terms Of Services</FooterLink>

              <FooterLink to="/inquiry">How it Works</FooterLink>
              <FooterLink to="/inquiry">Inquiry</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitles>Contact Us </FooterLinkTitles>
              <FooterLink to="/inquiry">Contact</FooterLink>

              <FooterLink to="/inquiry">Support</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>

        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              Kohinoor
            </SocialLogo>
            <WebsiteRights>
              Kohinoor © {new Date().getFullYear()} All rights reserved.
              Designed By{" "}
              <a href="https://www.linkedin.com/in/vivek-gupta-1636771a0/">
                Vivek Gupta
              </a>
              .
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="https://www.facebook.com/Kohinoor-Diamond-Polish-104200482002259/"
                target="_blank"
                aria-label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink
                href="https://instagram.com/kohinoordiamondpolish?utm_medium=copy_link"
                target="_blank"
                aria-label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.youtube.com/channel/UCUvowsr-eaCpOceOIby6DZQ"
                target="_blank"
                aria-label="YouTube"
              >
                <FaYoutube />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
