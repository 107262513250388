import React from "react";
import img1 from "../../images/img1.jpg";
import img2 from "../../ep/ep1.jpeg";
import img3 from "../../images/img3.jpeg";
import img4 from "../../images/img4.jpeg";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";

const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard to="/diamondpolish">
          <ServicesIcon src={img1} alt="diamond polish" />
          <ServicesH2>Diamond Polish</ServicesH2>
          <ServicesP>
            We are pioneers in offering Polishing And Plating Services to our
            deemed patrons.
          </ServicesP>
        </ServicesCard>
        <ServicesCard to="/hardchrome">
          <ServicesIcon src={img3} alt="hard chrome" />
          <ServicesH2>Hard Chrome</ServicesH2>
          <ServicesP>
            We are actively occupied in executing superb quality services for
            Hard Chrome Plating.
          </ServicesP>
        </ServicesCard>

        <ServicesCard to="/electroplating">
          <ServicesIcon src={img2} alt="electro plating" />
          <ServicesH2>Electro Plating</ServicesH2>
          <ServicesP>
            The material burns, evaporates or just melts away , providing and
            eye catching finish.
          </ServicesP>
        </ServicesCard>
        <ServicesCard to="/laser">
          <ServicesIcon src={img4} alt="laser engraving" />
          <ServicesH2>Photo Etching and Laser Engraving</ServicesH2>
          <ServicesP>
            The highly advanced technology today allows us the benefit of using
            a Laser to cut down almost anything and any form or design.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
