import React from "react";
import img from "../../images/svg2.svg";

import {
  WhyContainer,
  WhyWrapper,
  WhyRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrap,
  Img,
} from "./WhyElements";

const Why = ({
  lightBg,
  id,
  imgStart,

  topLine,
  lightText,
  headline,
  darkText,
  description,
  alt,
}) => {
  return (
    <>
      <WhyContainer lightBg={lightBg} id={id}>
        <WhyWrapper>
          <WhyRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Column2>
          </WhyRow>
        </WhyWrapper>
      </WhyContainer>
    </>
  );
};

export default Why;
