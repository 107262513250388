export const homeObjThree = {
  id: "contact",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Contact Us",
  headline: `Chhotubhai : 7405096301,
  
  Dhanvant bhai: 9998966515`,
  description: `Email: mail2kohinoor@gmail.com
  Address: 
  27/3/1, Ambika Estate,In Ghanshyam Estate,Opp. MamtaNagar So.,
    N.H. No.8, Margha Farm Road, Viratnagar Char Rasta, A'Bad.`,
  buttonLabel: "Inquiry",
  imgStart: true,

  alt: "about",
  dark: false,
  primary: false,
  darkText: true,
};
