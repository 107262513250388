export const homeObjSix = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Best Service",
  headline: "Service",
  description: `We strive to meet our customer’s needs by providing quality
  products'' and services. We are able to set up for each job
  quickly by utilizing our equipment, as well as manufacturing
  practices and policies that save time.`,
  imgStart: true,

  alt: "about",
  dark: true,
  primary: true,
  darkText: false,
};
