import React from "react";
import {
  ServicesContainer,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  DiamondH1,
} from "../HardChrome/ChromeElements";

import img1 from "../../hc/hc7.jpeg";
import img2 from "../../hc/hc1.jpeg";
import img3 from "../../hc/hc2.jpeg";
import img4 from "../../hc/hc3.jpeg";

import Footer from "../Footer";

const Chrome = () => {
  return (
    <>
      <ServicesContainer>
        <DiamondH1>Hard Chrome</DiamondH1>
        <ServicesWrapper>
          <ServicesCard>
            <ServicesIcon src={img1} alt="hard chrome polish"></ServicesIcon>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon
              src={img2}
              alt="hard chrome polish in ahmedabad"
            ></ServicesIcon>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon src={img3} alt="hard chrome in india"></ServicesIcon>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon
              src={img4}
              alt="best hard chrome polish"
            ></ServicesIcon>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
      <Footer />
    </>
  );
};

export default Chrome;
