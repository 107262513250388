export const homeObjTwo = {
  id: "whyus",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "We belive in quality",
  headline: "Why Us?",
  description: `Our organization has strongly settled its business in this domain due to our excellent quality array of Diamond Polishing, Hard Chrome Plating, Photo Etching, Laser Etching, etc.
    `,

  imgStart: false,
  img: require("../../images/svg2.svg"),
  alt: "about",
  dark: false,
  primary: false,
  darkText: true,
};
