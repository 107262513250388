import React, { useState } from "react";
import Contact from "../core/Contact";
import Quality from "../core/Quality";
import Hero from "../core/Hero";
import Info from "../core/Info";
import { homeObjOne } from "../core/Info/Data";
import { homeObjTwo } from "../core/Why/Data";
import { homeObjThree } from "../core/Contact/Data";
import { homeObjFour } from "../core/Quality/Data";
import { homeObjFive } from "../core/Team/Data";
import { homeObjSix } from "../core/Service/Data";
import Navbar from "../core/Navbar";
import Sidebar from "../core/Sidebar";
import Why from "../core/Why";
import Services from "../core/Services";
import Team from "../core/Team";
import Service from "../core/Service";

import Footer from "../core/Footer";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Hero />
      <Info {...homeObjOne} />
      <Why {...homeObjTwo} />
      <Quality {...homeObjFour} />
      <Team {...homeObjFive} />
      <Service {...homeObjSix} />
      <Contact {...homeObjThree} />
      <Services />
      <Footer />
    </>
  );
};

export default Home;
