import React from "react";
import {
  ServicesContainer,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  DiamondH1,
} from "../ElectroPlating/PlatingElements";

import img1 from "../../ep/ep1.jpeg";

import Footer from "../Footer/index.js";

const Electro = () => {
  return (
    <>
      <ServicesContainer>
        <DiamondH1>Electro Plating</DiamondH1>
        <ServicesWrapper>
          <ServicesCard>
            <ServicesIcon src={img1} alt="best electro plating"></ServicesIcon>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon
              src={img1}
              alt="die polish in ahmedabad"
            ></ServicesIcon>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon src={img1} alt="dai polish in india"></ServicesIcon>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon src={img1} alt="elctrolyte"></ServicesIcon>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
      <Footer />
    </>
  );
};

export default Electro;
