import React from "react";
import {
  ServicesContainer,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  DiamondH1,
} from "../Diamond/DiamondElements.js";

import img1 from "../../dp-small/dp2-small.jpg";
import img2 from "../../dp-small/dp3-small.jpg";
import img3 from "../../images/img1.jpg";
import img4 from "../../dp-small/dp5-small.jpg";

import Footer from "../Footer/index.js";

const Diamond = () => {
  return (
    <>
      <ServicesContainer>
        <DiamondH1>Diamond Polish</DiamondH1>
        <ServicesWrapper>
          <ServicesCard>
            <ServicesIcon
              src={img1}
              alt="best diamond polish in ahmedabad"
            ></ServicesIcon>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon
              src={img2}
              alt="best mould polish in india"
            ></ServicesIcon>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon
              src={img3}
              alt="die polish in ahmedabad"
            ></ServicesIcon>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon
              src={img4}
              alt="diamond polish in india"
            ></ServicesIcon>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
      <Footer />
    </>
  );
};

export default Diamond;
