import React from "react";

import Inquiry from "../core/Inquiry";
import ScrollToTop from "../core/ScrollToTop";

const InquiryPage = () => {
  return (
    <div>
      <ScrollToTop />
      <Inquiry />
    </div>
  );
};

export default InquiryPage;
