import React from "react";
import {
  ServicesContainer,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  DiamondH1,
} from "../Laser/LaserElements";

import img1 from "../../ls/ls1.jpeg";
import img2 from "../../ls/ls2.jpeg";
import img3 from "../../ls/ls3.jpeg";
import img4 from "../../ls/ls4.jpeg";

import Footer from "../Footer";

const Laser = () => {
  return (
    <>
      <ServicesContainer>
        <DiamondH1>Laser Engraving</DiamondH1>
        <ServicesWrapper>
          <ServicesCard>
            <ServicesIcon src={img1} alt="laser engraving"></ServicesIcon>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon src={img2} alt="photo etching"></ServicesIcon>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon src={img3} alt="laser marking"></ServicesIcon>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon src={img4} alt="laser engraving"></ServicesIcon>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
      <Footer />
    </>
  );
};

export default Laser;
