import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Diamond from "./core/Diamond";
import Electro from "./core/ElectroPlating";
import Chrome from "./core/HardChrome";

import Basic from "./core/Home";
import Laser from "./core/Laser";
import InquiryPage from "./pages/inquiry";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Basic} />
        <Route path="/diamondpolish" exact component={Diamond} />
        <Route path="/inquiry" exact component={InquiryPage} />
        <Route path="/hardchrome" exact component={Chrome} />
        <Route path="/laser" exact component={Laser} />
        <Route path="/electroplating" exact component={Electro} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
