import styled from "styled-components";

export const ServicesContainer = styled.div`
  height: 1000px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;

  @media screen and (max-width: 360px) {
    height: 1300px;

    background: black;
  }

  @media screen and (max-width: 768px) {
    height: 1300px;

    background: black;
  }

  @media screen and (max-width: 480px) {
    height: 1500px;

    background: black;
  }
`;

export const DiamondH1 = styled.div`
  font-size: 2.5rem;
  color: blue;
  margin-bottom: 64px;
  justify-content: center;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
    background: black;
  }
`;

export const ServicesWrapper = styled.div`
  max-width: 1200px;
  margin: 10 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 10px;
  padding: 0 10px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    max-width: 800px;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 10px;
  }
`;

export const ServicesCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 1200px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;
export const ServicesIcon = styled.img`
  height: 360px;
  width: 360px;
  margin-bottom: 5px;
  margin-top: 20px;

  @media screen and (max-width: 1000px) {
    height: 260px;
    width: 260px;
    margin-top: 20px;
  }
  @media screen and (max-width: 768px) {
    height: 260px;
    width: 260px;
    margin-top: 20px;
  }
`;
