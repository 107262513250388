import React from "react";
import Footer from "../Footer";
import emailjs from "emailjs-com";
import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton,
} from "./InquiryElements";

export default function Inquiry() {
  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_zdz77mh",
        "template_zd3eftj",
        e.target,
        "user_ZXC1zkC4RAquRByNj8t56"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <>
      <Container>
        <FormWrap>
          <Icon to="/">Kohinoor</Icon>
          <FormContent>
            <Form onSubmit={sendEmail}>
              <FormH1>Send Your Inquiry here</FormH1>
              <FormLabel htmlFor="for">Name</FormLabel>
              <FormInput type="name" required name="name" />
              <FormLabel htmlFor="for">Contact Number</FormLabel>
              <FormInput type="contact" name="number" />
              <FormLabel htmlFor="for">Email</FormLabel>
              <FormInput type="email" required name="email" />
              <FormLabel htmlFor="for">Message</FormLabel>
              <FormInput type="message" required name="message" />
              <FormButton type="submit">Submit</FormButton>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
      <Footer />
    </>
  );
}
