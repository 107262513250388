export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Welcome to",
  headline: "Kohinoor Diamond Polish",
  description: `Incepted in the year 2010, in Ahmedabad (Gujarat, India), we “Kohinoor
    Diamond Polish” are known as the reputed Service Provider of premium
    quality range of services diamond polishing, hard chrome plating,
    photo etching, laser etching  etc in All Over India.`,
  imgStart: true,

  alt: "best die polish in ahmedabad",
  dark: true,
  primary: true,
  darkText: false,
};
