export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Quality that satisfies your requirements",
  headline: "Quality",
  description: `Our organization has strongly settled its business in this domain
  due to our excellent quality array of diamond polishing, hard
  chrome plating, photo etching, laser etching, etc.`,
  imgStart: true,

  alt: "about",
  dark: true,
  primary: true,
  darkText: false,
};
