import React from "react";
import Home from "../pages";
import "../style.css";

const Basic = () => {
  return (
    <div>
      <Home />
    </div>
  );
};
export default Basic;
