export const homeObjFive = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Dedicated Team",
  headline: "Team",
  description: `Our organization has strongly settled its business in this domain due to our excellent quality array of Diamond Polishing, Hard Chrome Plating, Photo Etching, Laser Etching, etcOur enterprise has recruited a dedicated and hardworking team of
  professionals who have immense industry knowledge and experience
  in their associated domain..
      `,

  imgStart: false,

  alt: "about",
  dark: false,
  primary: true,
  darkText: false,
};
